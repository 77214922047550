
import { defineComponent } from 'vue'
import Search from '@/assets/svg/search.svg?inline'

export default defineComponent({
  name: 'SearchContainer',
  data () {
    return {
      searchTerm: this.searchData
    }
  },
  props: {
    searchData: String,
    placeholderData: String,
    searchFunction: {
      type: Function,
      default: () => {
        return false
      }
    }
  },
  components: {
    Search
  },
  watch: {
    searchTerm: {
      deep: true,
      handler () {
        if (this.searchTerm.length > 1 || this.searchTerm.length === 0) {
          this.searchFunction(this.searchTerm)
        }
      },
      immediate: true
    }
  },
  methods: {
  }
})
