import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a445134"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "search-container__search",
      placeholder: _ctx.placeholderData,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.searchTerm]
    ]),
    _createVNode(_component_Search, { class: "search-container__search-button" })
  ]))
}